import * as Parse from "parse";

export class PlantModel extends Parse.Object {
  className = "Plant";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get type(): string { return this.get("type"); }
  public set type(value: string) { this.set("type", value); }

  public get archived(): boolean { return this.get("archived"); }
  public set archived(value: boolean) { this.set("archived", value); }
}

Parse.Object.registerSubclass("Plant", PlantModel);

import * as Parse from "parse";

import { EmployeeModel } from "./employee.model";

export class GangModel extends Parse.Object {
  className = "Gang";

  public get employees(): EmployeeModel[] {
    const employees = this.get("employees");
    if (employees === null || employees === undefined) {
      this.set("employees", []);
    }
    return this.get("employees");
   }
  public set employees(value: EmployeeModel[]) { this.set("employees", value); }

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get region(): string { return this.get("region"); }
  public set region(value: string) { this.set("region", value); }
}

Parse.Object.registerSubclass("Gang", GangModel);

import * as Parse from "parse";

import { CompanyModel } from ".";

export class RateModel extends Parse.Object {
  className = "Rate";

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }

  public get type(): string { return this.get("type"); }
  public set type(value: string) { this.set("type", value); }

  public get minimumHours(): number { return this.get("minimumHours"); }
  public set minimumHours(value: number) { this.set("minimumHours", value); }

  public get minimumHoursRate(): number { return this.get("minimumHoursRate"); }
  public set minimumHoursRate(value: number) { this.set("minimumHoursRate", value); }

  public get vehicleType(): string { return this.get("vehicleType"); }
  public set vehicleType(value: string) { this.set("vehicleType", value); }

  public get hourlyRate(): number { return this.get("hourlyRate"); }
  public set hourlyRate(value: number) { this.set("hourlyRate", value); }
}

Parse.Object.registerSubclass("Rate", RateModel);

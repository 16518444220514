import { Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor() {
    Parse.initialize('app-id');
    let serverUrl = 'http://localhost:4002/api';
    if (environment.production) {
      serverUrl = 'https://alconex.herokuapp.com/api';
    }
    Parse.serverURL = serverUrl;
  }
}

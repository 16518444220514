import * as Parse from "parse";

export class PurchaseOrderModel extends Parse.Object {
  className = "PurchaseOrder";

  public get number(): string { return this.get("number"); }
  public set number(value: string) { this.set("number", value); }

  public get amount(): number { return this.get("amount"); }
  public set amount(value: number) { this.set("amount", value); }
}

Parse.Object.registerSubclass("PurchaseOrder", PurchaseOrderModel);

import * as Parse from "parse";

import { ContractModel } from "./contract.model";
import { CompanyModel, AttachmentModel } from ".";

export class PreSiteModel extends Parse.Object {
  className = "PreSite";

  public get name(): string { return this.get("name"); }

  public get type(): string { return this.get("type"); }
  public set type(value: string) { this.set("type", value); }

  public get region(): string { return this.get("region"); }
  public set region(value: string) { this.set("region", value); }

  public get contract(): ContractModel { return this.get("contract"); }
  public set contract(value: ContractModel) { this.set("contract", value); }

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }

  public get commercialDomestic(): string { return this.get("commercialDomestic"); }
  public set commercialDomestic(value: string) { this.set("commercialDomestic", value); }

  public get contactName(): string { return this.get("contactName"); }
  public set contactName(value: string) { this.set("contactName", value); }

  public get contactNumber(): string { return this.get("contactNumber"); }
  public set contactNumber(value: string) { this.set("contactNumber", value); }

  public get startDate(): Date { return this.get("startDate"); }
  public set startDate(value: Date) { this.set("startDate", value); }

  public get receivedDate(): Date { return this.get("receivedDate"); }
  public set receivedDate(value: Date) { this.set("receivedDate", value); }

  public get invoicedDate(): Date { return this.get("invoicedDate"); }
  public set invoicedDate(value: Date) { this.set("invoicedDate", value); }

  public get address(): string { return this.get("address"); }
  public set address(value: string) { this.set("address", value); }

  public get information(): string { return this.get("information"); }
  public set information(value: string) { this.set("information", value); }

  public get description(): string { return this.get("description"); }
  public set description(value: string) { this.set("description", value); }

  public get dueDate(): Date { return this.get("dueDate"); }
  public set dueDate(value: Date) { this.set("dueDate", value); }

  public get submittedDate(): Date { return this.get("submittedDate"); }
  public set submittedDate(value: Date) { this.set("submittedDate", value); }

  public get quotedOrRated(): string { return this.get("quotedOrRated"); }
  public set quotedOrRated(value: string) { this.set("quotedOrRated", value); }

  public get cost(): number { return this.get("cost"); }
  public set cost(value: number) { this.set("cost", value); }

  public get completedPack(): boolean { return this.get("completedPack"); }
  public set completedPack(value: boolean) { this.set("completedPack", value); }

  public get paperwork(): AttachmentModel[] {
    const files = this.get("paperwork");
    if (files === null || files === undefined) {
      this.set("paperwork", []);
    }
    return this.get("paperwork");
  }
  public set paperwork(value: AttachmentModel[]) { this.set("paperwork", value); }
}

Parse.Object.registerSubclass("PreSite", PreSiteModel);

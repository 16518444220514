import * as Parse from "parse";

export class WaterExcavationModel extends Parse.Object {
  className = "WaterExcavation";

  public get job(): Date { return this.get("job"); }
  public set job(value: Date) { this.set("job", value); }

  public get startDate(): Date { return this.get("startDate"); }
  public set startDate(value: Date) { this.set("startDate", value); }

  public get startTime(): Date { return this.get("startTime"); }
  public set startTime(value: Date) { this.set("startTime", value); }

  public get endDate(): Date { return this.get("endDate"); }
  public set endDate(value: Date) { this.set("endDate", value); }

  public get endTime(): Date { return this.get("endTime"); }
  public set endTime(value: Date) { this.set("endTime", value); }

  public get length(): number { return this.get("length"); }
  public set length(value: number) { this.set("length", value); }

  public get width(): number { return this.get("width"); }
  public set width(value: number) { this.set("width", value); }

  public get depth(): number { return this.get("depth"); }
  public set depth(value: number) { this.set("depth", value); }

  public get backfill(): string { return this.get("backfill"); }
  public set backfill(value: string) { this.set("backfill", value); }

  public get reinstatement(): string { return this.get("reinstatement"); }
  public set reinstatement(value: string) { this.set("reinstatement", value); }

  public get concrete(): boolean { return this.get("concrete"); }
  public set concrete(value: boolean) { this.set("concrete", value); }

  public get tarmac(): boolean { return this.get("tarmac"); }
  public set tarmac(value: boolean) { this.set("tarmac", value); }

  public get hotBox(): boolean { return this.get("hotBox"); }
  public set hotBox(value: boolean) { this.set("hotBox", value); }

  public get slabs(): boolean { return this.get("slabs"); }
  public set slabs(value: boolean) { this.set("slabs", value); }

  public get blocks(): boolean { return this.get("blocks"); }
  public set blocks(value: boolean) { this.set("blocks", value); }

  public get gravel(): boolean { return this.get("gravel"); }
  public set gravel(value: boolean) { this.set("gravel", value); }

  public get other(): boolean { return this.get("other"); }
  public set other(value: boolean) { this.set("other", value); }
}

Parse.Object.registerSubclass("WaterExcavation", WaterExcavationModel);

import * as Parse from "parse";

import { EmployeeModel } from "./employee.model";
import { JobVisitModel } from "./job-visit.model";

export class EmployeeJobVisitModel extends Parse.Object {
  className = "EmployeeJobVisit";

  public get employee(): EmployeeModel { return this.get("employee"); }
  public set employee(value: EmployeeModel) { this.set("employee", value); }

  public get jobVisit(): JobVisitModel { return this.get("jobVisit"); }
  public set jobVisit(value: JobVisitModel) { this.set("jobVisit", value); }

  public get start(): Date { return this.get("start"); }
  public set start(value: Date) { this.set("start", value); }

  public get end(): Date { return this.get("end"); }
  public set end(value: Date) { this.set("end", value); }

  public get startSignature(): Parse.File { return this.get("startSignature"); }
  public set startSignature(value: Parse.File) { this.set("startSignature", value); }
}

Parse.Object.registerSubclass("EmployeeJobVisit", EmployeeJobVisitModel);

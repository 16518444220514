import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalAnimationComponent } from './portal-animation/portal-animation.component';
import { MultipleAttachmentsFieldComponent } from './multiple-attachments-field/multiple-attachments-field.component';
import { AttachmentCardComponent } from './attachment-card/attachment-card.component';
import { JobVisitCardComponent } from './job-visit-card/job-visit-card.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PortalAnimationComponent,
    MultipleAttachmentsFieldComponent,
    AttachmentCardComponent,
    JobVisitCardComponent
  ],
  exports: [
    PortalAnimationComponent,
    MultipleAttachmentsFieldComponent,
    AttachmentCardComponent,
    JobVisitCardComponent
  ]
})
export class SharedModule { }

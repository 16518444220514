import { Component } from '@angular/core';
import { AuthenticationService } from '@authentication/authentication.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {

  public isMinimised = false;

  constructor(authenticationService: AuthenticationService) {
    authenticationService.user.subscribe(user => {
      if (user) {
        this.isMinimised = true;
      } else {
        this.isMinimised = false;
      }
    });
  }

}

import * as Parse from "parse";

export class EmployeeModel extends Parse.Object {
  className = "Employee";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get subOrganisation(): string { return this.get("subOrganisation"); }
  public set subOrganisation(value: string) { this.set("subOrganisation", value); }

  public get archived(): boolean { return this.get("archived"); }
  public set archived(value: boolean) { this.set("archived", value); }

  public get manager(): EmployeeModel { return this.get("manager"); }
  public set manager(value: EmployeeModel) { this.set("manager", value); }

  public get holidayEntitlement(): number { return this.get("holidayEntitlement"); }
  public set holidayEntitlement(value: number) { this.set("holidayEntitlement", value); }

  // PBU

  public get markup(): boolean { return this.get("markup"); }
  public set markup(value: boolean) { this.set("markup", value); }

  public get drilling(): boolean { return this.get("drilling"); }
  public set drilling(value: boolean) { this.set("drilling", value); }

  public get excavation(): boolean { return this.get("excavation"); }
  public set excavation(value: boolean) { this.set("excavation", value); }

  public get weld(): boolean { return this.get("weld"); }
  public set weld(value: boolean) { this.set("weld", value); }

  public get cablePuller(): boolean { return this.get("cablePuller"); }
  public set cablePuller(value: boolean) { this.set("cablePuller", value); }

  public get water(): boolean { return this.get("water"); }
  public set water(value: boolean) { this.set("water", value); }

  // AquaJet

  public get jetting(): boolean { return this.get("jetting"); }
  public set jetting(value: boolean) { this.set("jetting", value); }

  public get class2Driver(): boolean { return this.get("class2Driver"); }
  public set class2Driver(value: boolean) { this.set("class2Driver", value); }

  public get jetVacOperative(): boolean { return this.get("jetVacOperative"); }
  public set jetVacOperative(value: boolean) { this.set("jetVacOperative", value); }

  public get lining(): boolean { return this.get("lining"); }
  public set lining(value: boolean) { this.set("lining", value); }

  public get cctv(): boolean { return this.get("cctv"); }
  public set cctv(value: boolean) { this.set("cctv", value); }
}

Parse.Object.registerSubclass("Employee", EmployeeModel);

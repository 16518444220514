import * as Parse from "parse";
import { JobModel } from "./job.model";
import { TracksheetItemModel } from "./tracksheet-item.model";

export class TracksheetModel extends Parse.Object {
  className = "Tracksheet";

  public get job(): JobModel { return this.get("job"); }
  public set job(value: JobModel) { this.set("job", value); }

  public get items(): TracksheetItemModel[] {
    const items = this.get("items");
    if (items === null || items === undefined) {
      this.set("items", []);
    }
    return this.get("items");
  }
  public set items(value: TracksheetItemModel[]) { this.set("items", value); }

  public get notes(): string { return this.get("notes"); }
  public set notes(value: string) { this.set("notes", value); }

}
Parse.Object.registerSubclass("Tracksheet", TracksheetModel);

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
}

.card {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.125);
  background-color: #f7f7f7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/job-visit-card/job-visit-card.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,kCAAA;EACA,yBAAA;AACJ","sourcesContent":[".image-preview {\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    height: 160px;\n  }\n\n.card {\n    cursor: pointer;\n    border-color: rgba(0, 0, 0, 0.125);\n    background-color: #f7f7f7;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

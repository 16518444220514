import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './page-not-found.component';
import { AuthenticationGuardService } from './services/authentication-guard.service';
import { AuthenticationService } from '@authentication/authentication.service';
import { SharedModule } from '@shared/shared.module';
import { TopBarComponent } from './top-bar/top-bar.component';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule), canActivate: [ AuthenticationGuardService ] },
  { path: 'sign-in', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TopBarComponent
  ],
  imports: [
    GoogleMapsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';

import { AttachmentModel } from '../../../model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-multiple-attachments-field',
  templateUrl: './multiple-attachments-field.component.html',
  styleUrls: ['./multiple-attachments-field.component.scss'],
})
export class MultipleAttachmentsFieldComponent {

  public isFileDragging = false;

  @Input() editable = true;
  @Input() requiresExpiryDate = false;
  @Input() attachmentsArray: [AttachmentModel];
  @Output() attachmentsArrayChange = new EventEmitter();

  @Input() narrow = false;

  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;


  constructor(private changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private snackBar: MatSnackBar) { }

}

import * as Parse from "parse";

import {
  AttachmentModel,
  ContractModel,
  RigModel,
  PlantModel,
  EmployeeModel,
  CompanyModel,
  WorkSlotModel,
  TenderModel,
  WaterSiteVisitModel,
  PreSiteModel,
  AdditionalCostItemModel,
  ReinstatementModel,
  InvoiceModel
} from ".";
import { WaterExcavationModel } from "./water-excavation.model";

export class JobModel extends Parse.Object {
  className = "Job";

  public get name(): string { return this.get("name"); }
  public get index(): number { return this.get("index"); }

  public get contract(): ContractModel { return this.get("contract"); }
  public set contract(value: ContractModel) { this.set("contract", value); }

  public get description(): string { return this.get("description"); }
  public set description(value: string) { this.set("description", value); }

  public get startDate(): Date { return this.get("startDate"); }
  public set startDate(value: Date) { this.set("startDate", value); }

  public get endDate(): Date { return this.get("endDate"); }
  public set endDate(value: Date) { this.set("endDate", value); }

  public get workComplete(): string { return this.get("workComplete"); }
  public set workComplete(value: string) { this.set("workComplete", value); }

  public get invoiced(): string { return this.get("invoiced"); }
  public set invoiced(value: string) { this.set("invoiced", value); }

  public get paid(): string { return this.get("paid"); }
  public set paid(value: string) { this.set("paid", value); }

  public get paymentDate(): string { return this.get("paymentDate"); }
  public set paymentDate(value: string) { this.set("paymentDate", value); }

  public get invoices(): InvoiceModel[] {
    const invoices = this.get("invoices");
    if (invoices === null || invoices === undefined) {
      this.set("invoices", []);
    }
    return this.get("invoices");
  }
  public set invoices(value: InvoiceModel[]) { this.set("invoices", value); }

  public get daysAborted(): Date[] {
    const daysAborted = this.get("daysAborted");
    if (daysAborted === null || daysAborted === undefined) {
      this.set("daysAborted", []);
    }
    return this.get("daysAborted");
  }
  public set daysAborted(value: Date[]) { this.set("daysAborted", value); }

  public get workstream(): string { return this.get("workstream"); }
  public set workstream(value: string) { this.set("workstream", value); }

  public get address(): string { return this.get("address"); }
  public set address(value: string) { this.set("address", value); }

  public get hddTeamRequired(): boolean { return this.get("hddTeamRequired"); }
  public set hddTeamRequired(value: boolean) { this.set("hddTeamRequired", value); }
  public get hddTeam(): EmployeeModel[] {
    const hddTeam = this.get("hddTeam");
    if (hddTeam === null || hddTeam === undefined) {
      this.set("hddTeam", []);
    }
    return this.get("hddTeam");
  }
  public set hddTeam(value: EmployeeModel[]) { this.set("hddTeam", value); }

  public get siteMarkupTeamRequired(): boolean { return this.get("siteMarkupTeamRequired"); }
  public set siteMarkupTeamRequired(value: boolean) { this.set("siteMarkupTeamRequired", value); }
  public get siteMarkupTeam(): EmployeeModel[] {
    const files = this.get("siteMarkupTeam");
    if (files === null || files === undefined) {
      this.set("siteMarkupTeam", []);
    }
    return this.get("siteMarkupTeam");
  }
  public set siteMarkupTeam(value: EmployeeModel[]) { this.set("siteMarkupTeam", value); }

  public get excavationTeamRequired(): boolean { return this.get("excavationTeamRequired"); }
  public set excavationTeamRequired(value: boolean) { this.set("excavationTeamRequired", value); }
  public get excavationTeam(): EmployeeModel[] {
    const files = this.get("excavationTeam");
    if (files === null || files === undefined) {
      this.set("excavationTeam", []);
    }
    return this.get("excavationTeam");
  }
  public set excavationTeam(value: EmployeeModel[]) { this.set("excavationTeam", value); }

  public get drillingTeamRequired(): boolean { return this.get("drillingTeamRequired"); }
  public set drillingTeamRequired(value: boolean) { this.set("drillingTeamRequired", value); }
  public get drillingTeam(): EmployeeModel[] {
    const files = this.get("drillingTeam");
    if (files === null || files === undefined) {
      this.set("drillingTeam", []);
    }
    return this.get("drillingTeam");
  }
  public set drillingTeam(value: EmployeeModel[]) { this.set("drillingTeam", value); }

  public get weldTeamRequired(): boolean { return this.get("weldTeamRequired"); }
  public set weldTeamRequired(value: boolean) { this.set("weldTeamRequired", value); }
  public get weldTeam(): EmployeeModel[] {
    const files = this.get("weldTeam");
    if (files === null || files === undefined) {
      this.set("weldTeam", []);
    }
    return this.get("weldTeam");
  }
  public set weldTeam(value: EmployeeModel[]) { this.set("weldTeam", value); }

  public get cablePullerTeamRequired(): boolean { return this.get("cablePullerTeamRequired"); }
  public set cablePullerTeamRequired(value: boolean) { this.set("cablePullerTeamRequired", value); }
  public get cablePullerTeam(): EmployeeModel[] {
    const files = this.get("cablePullerTeam");
    if (files === null || files === undefined) {
      this.set("cablePullerTeam", []);
    }
    return this.get("cablePullerTeam");
  }
  public set cablePullerTeam(value: EmployeeModel[]) { this.set("cablePullerTeam", value); }

  public get ramsCompleted(): string { return this.get("ramsCompleted"); }
  public set ramsCompleted(value: string) { this.set("ramsCompleted", value); }
  public get ramsAttachments(): AttachmentModel[] {
    const files = this.get("ramsAttachments");
    if (files === null || files === undefined) {
      this.set("ramsAttachments", []);
    }
    return this.get("ramsAttachments");
  }
  public set ramsAttachments(value: AttachmentModel[]) { this.set("ramsAttachments", value); }

  public get jobPackCompleted(): string { return this.get("jobPackCompleted"); }
  public set jobPackCompleted(value: string) { this.set("jobPackCompleted", value); }
  public get jobPackAttachments(): AttachmentModel[] {
    const files = this.get("jobPackAttachments");
    if (files === null || files === undefined) {
      this.set("jobPackAttachments", []);
    }
    return this.get("jobPackAttachments");
  }
  public set jobPackAttachments(value: AttachmentModel[]) { this.set("jobPackAttachments", value); }

  public get auditAttachments(): AttachmentModel[] {
    const files = this.get("auditAttachments");
    if (files === null || files === undefined) {
      this.set("auditAttachments", []);
    }
    return this.get("auditAttachments");
  }
  public set auditAttachments(value: AttachmentModel[]) { this.set("auditAttachments", value); }

  public get asLaidAttachments(): AttachmentModel[] {
    const files = this.get("asLaidAttachments");
    if (files === null || files === undefined) {
      this.set("asLaidAttachments", []);
    }
    return this.get("asLaidAttachments");
  }
  public set asLaidAttachments(value: AttachmentModel[]) { this.set("asLaidAttachments", value); }

  public get rigs(): RigModel[] {
    const rigs = this.get("rigs");
    if (rigs === null || rigs === undefined) {
      this.set("rigs", []);
    }
    return this.get("rigs");
   }
  public set rigs(value: RigModel[]) { this.set("rigs", value); }

  public get plantItems(): PlantModel[] {
    const files = this.get("plantItems");
    if (files === null || files === undefined) {
      this.set("plantItems", []);
    }
    return this.get("plantItems");
  }
  public set plantItems(value: PlantModel[]) { this.set("plantItems", value); }

  // Civils Only

  public get reinstatements(): ReinstatementModel[] {
    const reinstatements = this.get("reinstatements");
    if (reinstatements === null || reinstatements === undefined) {
      this.set("reinstatements", []);
    }
    return this.get("reinstatements");
  }
  public set reinstatements(value: ReinstatementModel[]) { this.set("reinstatements", value); }

  // Sewerage Only

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }

  public get cctvTeamRequired(): boolean { return this.get("cctvTeamRequired"); }
  public set cctvTeamRequired(value: boolean) { this.set("cctvTeamRequired", value); }
  public get cctvWorkSlots(): WorkSlotModel[] {
    const workSlots = this.get("cctvWorkSlots");
    if (workSlots === null || workSlots === undefined) {
      this.set("cctvWorkSlots", []);
    }
    return this.get("cctvWorkSlots");
  }
  public set cctvWorkSlots(value: WorkSlotModel[]) { this.set("cctvWorkSlots", value); }

  public get tankerTeamRequired(): boolean { return this.get("tankerTeamRequired"); }
  public set tankerTeamRequired(value: boolean) { this.set("tankerTeamRequired", value); }
  public get tankerWorkSlots(): WorkSlotModel[] {
    const workSlots = this.get("tankerWorkSlots");
    if (workSlots === null || workSlots === undefined) {
      this.set("tankerWorkSlots", []);
    }
    return this.get("tankerWorkSlots");
  }
  public set tankerWorkSlots(value: WorkSlotModel[]) { this.set("tankerWorkSlots", value); }

  public get jettingTeamRequired(): boolean { return this.get("jettingTeamRequired"); }
  public set jettingTeamRequired(value: boolean) { this.set("jettingTeamRequired", value); }
  public get jettingWorkSlots(): WorkSlotModel[] {
    const workSlots = this.get("jettingWorkSlots");
    if (workSlots === null || workSlots === undefined) {
      this.set("jettingWorkSlots", []);
    }
    return this.get("jettingWorkSlots");
  }
  public set jettingWorkSlots(value: WorkSlotModel[]) { this.set("jettingWorkSlots", value); }

  public get excavationWorkSlots(): WorkSlotModel[] {
    const workSlots = this.get("excavationWorkSlots");
    if (workSlots === null || workSlots === undefined) {
      this.set("excavationWorkSlots", []);
    }
    return this.get("excavationWorkSlots");
  }
  public set excavationWorkSlots(value: WorkSlotModel[]) { this.set("excavationWorkSlots", value); }

  // public get vehicles(): VehicleModel[] {
  //   const files = this.get('vehicles');
  //   if (files === null || files === undefined) {
  //     this.set('vehicles', []);
  //   }
  //   return this.get('vehicles');
  // }
  // public set vehicles(value: VehicleModel[]) { this.set('vehicles', value); }

  public get clientOrderNumber(): string { return this.get("clientOrderNumber"); }
  public set clientOrderNumber(value: string) { this.set("clientOrderNumber", value); }

  public get jobSheetReceived(): string { return this.get("jobSheetReceived"); }
  public set jobSheetReceived(value: string) { this.set("jobSheetReceived", value); }

  public get paymentType(): string { return this.get("paymentType"); }
  public set paymentType(value: string) { this.set("paymentType", value); }

  public get riskAssessment(): string { return this.get("riskAssessment"); }
  public set riskAssessment(value: string) { this.set("riskAssessment", value); }

  public get startTime(): Date { return this.get("startTime"); }
  public set startTime(value: Date) { this.set("startTime", value); }

  public get endTime(): Date { return this.get("endTime"); }
  public set endTime(value: Date) { this.set("endTime", value); }

  public get totalRevenue(): number { return this.get("totalRevenue"); }
  public set totalRevenue(value: number) { this.set("totalRevenue", value); }

  public get contactName(): string { return this.get("contactName"); }
  public set contactName(value: string) { this.set("contactName", value); }

  public get contactNumber(): string { return this.get("contactNumber"); }
  public set contactNumber(value: string) { this.set("contactNumber", value); }

  public get quotedOrRated(): string { return this.get("quotedOrRated"); }
  public set quotedOrRated(value: string) { this.set("quotedOrRated", value); }

  public get tender(): TenderModel { return this.get("tender"); }
  public set tender(value: TenderModel) { this.set("tender", value); }

  // Water Only

  public get scopeOfWork(): string { return this.get("scopeOfWork"); }
  public set scopeOfWork(value: string) { this.set("scopeOfWork", value); }

  public get preSite(): PreSiteModel { return this.get("preSite"); }
  public set preSite(value: PreSiteModel) { this.set("preSite", value); }

  public get internalWorkCompleted(): string { return this.get("internalWorkCompleted"); }
  public set internalWorkCompleted(value: string) { this.set("internalWorkCompleted", value); }

  public get externalWorkCompleted(): string { return this.get("externalWorkCompleted"); }
  public set externalWorkCompleted(value: string) { this.set("externalWorkCompleted", value); }

  public get waterSiteVisits(): WaterSiteVisitModel[] {
    const siteVisits = this.get("waterSiteVisits");
    if (siteVisits === null || siteVisits === undefined) {
      this.set("waterSiteVisits", []);
    }
    return this.get("waterSiteVisits");
  }
  public set waterSiteVisits(value: WaterSiteVisitModel[]) { this.set("waterSiteVisits", value); }

  public get waterExcavations(): WaterExcavationModel[] {
    const excavations = this.get("waterExcavations");
    if (excavations === null || excavations === undefined) {
      this.set("waterExcavations", []);
    }
    return this.get("waterExcavations");
  }
  public set waterExcavations(value: WaterExcavationModel[]) { this.set("waterExcavations", value); }

  public get plantCostItems(): WaterExcavationModel[] {
    const items = this.get("plantCostItems");
    if (items === null || items === undefined) {
      this.set("plantCostItems", []);
    }
    return this.get("plantCostItems");
  }
  public set plantCostItems(value: WaterExcavationModel[]) { this.set("plantCostItems", value); }

  public get additionalCostItems(): AdditionalCostItemModel[] {
    const items = this.get("additionalCostItems");
    if (items === null || items === undefined) {
      this.set("additionalCostItems", []);
    }
    return this.get("additionalCostItems");
  }
  public set additionalCostItems(value: AdditionalCostItemModel[]) { this.set("additionalCostItems", value); }

  // Sewerage & Water

  public get cancelledReason(): string { return this.get("cancelledReason"); }
  public set cancelledReason(value: string) { this.set("cancelledReason", value); }

  // Alconex

  public get team(): EmployeeModel[] {
    const files = this.get("team");
    if (files === null || files === undefined) {
      this.set("team", []);
    }
    return this.get("team");
  }
  public set team(value: EmployeeModel[]) { this.set("team", value); }

  public get dateDeveloperSentIn(): Date { return this.get("dateDeveloperSentIn"); }
  public set dateDeveloperSentIn(value: Date) { this.set("dateDeveloperSentIn", value); }

  public get deadlineDate(): Date { return this.get("deadlineDate"); }
  public set deadlineDate(value: Date) { this.set("deadlineDate", value); }

  public get scheduledStartDate(): Date { return this.get("scheduledStartDate"); }
  public set scheduledStartDate(value: Date) { this.set("scheduledStartDate", value); }

  public get scheduledEndDate(): Date { return this.get("scheduledEndDate"); }
  public set scheduledEndDate(value: Date) { this.set("scheduledEndDate", value); }

  public get work(): String { return this.get("work"); }
  public set work(value: String) { this.set("work", value); }

  public get status(): String { return this.get("status"); }
  public set status(value: String) { this.set("status", value); }

  public get electricMainsServicesDocuments(): AttachmentModel[] {
    const files = this.get("electricMainsServicesDocuments");
    if (files === null || files === undefined) {
      this.set("electricMainsServicesDocuments", []);
    }
    return this.get("electricMainsServicesDocuments");
  }
  public set electricMainsServicesDocuments(value: AttachmentModel[]) { this.set("electricMainsServicesDocuments", value); }

  public get gasMainsServicesDocuments(): AttachmentModel[] {
    const files = this.get("gasMainsServicesDocuments");
    if (files === null || files === undefined) {
      this.set("gasMainsServicesDocuments", []);
    }
    return this.get("gasMainsServicesDocuments");
  }
  public set gasMainsServicesDocuments(value: AttachmentModel[]) { this.set("gasMainsServicesDocuments", value); }
}

Parse.Object.registerSubclass("Job", JobModel);

import * as Parse from "parse";

export class OrganisationModel extends Parse.Object {
  className = "Organisation";

  // Name
  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  // Status (Only ever set by the server).
  public get status(): string { return this.get("status"); }
}

Parse.Object.registerSubclass("Organisation", OrganisationModel);

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

type ObservableUser = ReplaySubject<Parse.User>;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public roles: Parse.Role[] = [];

  public user: ObservableUser = new ReplaySubject<Parse.User>(1);

  constructor(private injector: Injector) {

    this.user = new ReplaySubject<Parse.User>(1);
    this.user.next(Parse.User.current());
    this.fetchUser();
  }

  public async init() {
  }

  public async signIn(username: string, password: string): Promise<Parse.User> {
    const user = await Parse.User.logIn(username, password);
    if (user.attributes.blocked === true) {
      Parse.User.logOut();
      throw new Error('Blocked');
    }
    this.user.next(Parse.User.current());
    return Parse.User.current();
  }

  public resetPassword(email: string): Promise<Parse.User> {
    return Parse.User.requestPasswordReset(email);
  }

  public isLoggedIn(): boolean {
    return Parse.User.current() !== null;
  }

  public mustChangePassword(): boolean {
    if (Parse.User.current() === undefined || Parse.User.current() === null) {
      return false;
    }
    return Parse.User.current().get('mustChangePassword');
  }

  public currentUser(): Parse.User {
    return Parse.User.current();
  }

  public changePassword(password: string): Promise<Parse.User> {
    const user = this.currentUser();
    user.set('mustChangePassword', false);
    user.setPassword(password);
    return user.save();
  }

  public async signOut(): Promise<Parse.User> {
    const result = await Parse.User.logOut();
    this.roles = [];
    this.user.next(undefined);
    return result;
  }

  // Used as this service can be used as an initializer and router won't exist,
  // so we have to lazy load it.
  public get router(): Router {
    return this.injector.get(Router);
  }

  public async fetchUser() {
    const user = Parse.User.current();
    if (user) {
      try {
        const fetchedUser = await user.fetch();
        if (fetchedUser.attributes.blocked === true) {
          this.signOut();
          return;
        }
        this.roles = await this.fetchRoles(user);
        this.user.next(user);
      } catch (exception) {
        this.signOut();

        this.router.navigate(['sign-in']);
      }
    }
    return user;
  }

  private async fetchRoles(user: Parse.User): Promise<Parse.Role[]> {
    const roleQuery = new Parse.Query<Parse.Role>(Parse.Role).equalTo('users', user);
    return roleQuery.find();
  }

  public hasRole(name: string): boolean {
    for (let i = 0; i < this.roles.length; i++) {
      const role = this.roles[i];
      if (role.getName() === name) {
        return true;
      }
    }
    return false;
  }
}

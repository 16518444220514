import * as Parse from "parse";

import { CompanyModel, AttachmentModel, TenderModel, PurchaseOrderModel } from ".";
import { LocationModel } from './location.model';

export class ContractModel extends Parse.Object {
  className = "Contract";

  public get name(): string { return this.get("name"); }
  public get index(): number { return this.get("index"); }

  public get tender(): TenderModel { return this.get("tender"); }
  public set tender(value: TenderModel) { this.set("tender", value); }

  public get region(): string { return this.get("region"); }
  public set region(value: string) { this.set("region", value); }

  public get workstream(): string { return this.get("workstream"); }
  public set workstream(value: string) { this.set("workstream", value); }

  public get operationalLead(): Parse.User { return this.get("operationalLead"); }
  public set operationalLead(value: Parse.User) { this.set("operationalLead", value); }

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }

  public get project(): string { return this.get("project"); }
  public set project(value: string) { this.set("project", value); }

  public get poNumber(): string { return this.get("poNumber"); }
  public set poNumber(value: string) { this.set("poNumber", value); }

  public get paymentTerms(): number { return this.get("paymentTerms"); }
  public set paymentTerms(value: number) { this.set("paymentTerms", value); }

  public get retentionPercentage(): number { return this.get("retentionPercentage"); }
  public set retentionPercentage(value: number) { this.set("retentionPercentage", value); }

  public get purchaseOrderDocuments(): AttachmentModel[] {
    const files = this.get("purchaseOrderDocuments");
    if (files === null || files === undefined) {
      this.set("purchaseOrderDocuments", []);
    }
    return this.get("purchaseOrderDocuments");
  }
  public set purchaseOrderDocuments(value: AttachmentModel[]) { this.set("purchaseOrderDocuments", value); }

  public get purchaseOrders(): PurchaseOrderModel[] {
    const purchaseOrders = this.get("purchaseOrders");
    if (purchaseOrders === null || purchaseOrders === undefined) {
      this.set("purchaseOrders", []);
    }
    return this.get("purchaseOrders");
  }
  public set purchaseOrders(value: PurchaseOrderModel[]) { this.set("purchaseOrders", value); }

  public get isDefaultWaterContract(): boolean { return this.get("isDefaultWaterContract"); }

  // Alconex Properties
  public get schemeLocation(): LocationModel {  return this.get("schemeLocation");  }
  public set schemeLocation(value: LocationModel) { this.set("schemeLocation", value); }
  
  public get schemeName(): string { return this.get("schemeName"); }
  public set schemeName(value: string) { this.set("schemeName", value); }

  public get schemeReference(): string { return this.get("schemeReference"); }
  public set schemeReference(value: string) { this.set("schemeReference", value); }

  public get status(): string { return this.get("status"); }
  public set status(value: string) { this.set("status", value); }

  public get siteAddress(): string { return this.get("siteAddress"); }
  public set siteAddress(value: string) { this.set("siteAddress", value); }

  public get dateAccepted(): Date { return this.get("dateAccepted"); }
  public set dateAccepted(value: Date) { this.set("dateAccepted", value); }

  public get dateDepositReceived(): Date { return this.get("dateDepositReceived"); }
  public set dateDepositReceived(value: Date) { this.set("dateDepositReceived", value); }

  public get electric(): boolean { return this.get("electric"); }
  public set electric(value: boolean) { this.set("electric", value); }

  public get water(): boolean { return this.get("water"); }
  public set water(value: boolean) { this.set("water", value); }

  public get gas(): boolean { return this.get("gas"); }
  public set gas(value: boolean) { this.set("gas", value); }

  public get excavation(): string { return this.get("excavation"); }
  public set excavation(value: string) { this.set("excavation", value); }

  public get developerContactName(): string { return this.get("developerContactName"); }
  public set developerContactName(value: string) { this.set("developerContactName", value); }

  public get developerContactNumber(): string { return this.get("developerContactNumber"); }
  public set developerContactNumber(value: string) { this.set("developerContactNumber", value); }

  public get developerContactEmail(): string { return this.get("developerContactEmail"); }
  public set developerContactEmail(value: string) { this.set("developerContactEmail", value); }

  public get preStartMeeting(): Date { return this.get("preStartMeeting"); }
  public set preStartMeeting(value: Date) { this.set("preStartMeeting", value); }

  public get siteManagerContactName(): string { return this.get("siteManagerContactName"); }
  public set siteManagerContactName(value: string) { this.set("siteManagerContactName", value); }

  public get siteManagerContactNumber(): string { return this.get("siteManagerContactNumber"); }
  public set siteManagerContactNumber(value: string) { this.set("siteManagerContactNumber", value); }

  public get siteManagerContactEmail(): string { return this.get("siteManagerContactEmail"); }
  public set siteManagerContactEmail(value: string) { this.set("siteManagerContactEmail", value); }

  public get dnoName(): string { return this.get("dnoName"); }
  public set dnoName(value: string) { this.set("dnoName", value); }

  public get dnoReference(): string { return this.get("dnoReference"); }
  public set dnoReference(value: string) { this.set("dnoReference", value); }

  public get idnoName(): string { return this.get("idnoName"); }
  public set idnoName(value: string) { this.set("idnoName", value); }

  public get idnoReference(): string { return this.get("idnoReference"); }
  public set idnoReference(value: string) { this.set("idnoReference", value); }

  public get electricMeterCallOff(): string { return this.get("electricMeterCallOff"); }
  public set electricMeterCallOff(value: string) { this.set("electricMeterCallOff", value); }

  public get meterType(): string { return this.get("meterType"); }
  public set meterType(value: string) { this.set("meterType", value); }

  public get nominatedSupplier(): string { return this.get("nominatedSupplier"); }
  public set nominatedSupplier(value: string) { this.set("nominatedSupplier", value); }

  public get gtName(): string { return this.get("gtName"); }
  public set gtName(value: string) { this.set("gtName", value); }

  public get gtReference(): string { return this.get("gtReference"); }
  public set gtReference(value: string) { this.set("gtReference", value); }

  public get igtName(): string { return this.get("igtName"); }
  public set igtName(value: string) { this.set("igtName", value); }

  public get igtReference(): string { return this.get("igtReference"); }
  public set igtReference(value: string) { this.set("igtReference", value); }

  public get waterAuthority(): string { return this.get("waterAuthority"); }
  public set waterAuthority(value: string) { this.set("waterAuthority", value); }

  public get waterReference(): string { return this.get("waterReference"); }
  public set waterReference(value: string) { this.set("waterReference", value); }

  public get preStartDocuments(): AttachmentModel[] {
    const files = this.get("preStartDocuments");
    if (files === null || files === undefined) {
      this.set("preStartDocuments", []);
    }
    return this.get("preStartDocuments");
  }
  public set preStartDocuments(value: AttachmentModel[]) { this.set("preStartDocuments", value); }

  public get trackSheet(): AttachmentModel[] {
    const files = this.get("trackSheet");
    if (files === null || files === undefined) {
      this.set("trackSheet", []);
    }
    return this.get("trackSheet");
  }
  public set trackSheet(value: AttachmentModel[]) { this.set("trackSheet", value); }

  public get financialDocuments(): AttachmentModel[] {
    const files = this.get("financialDocuments");
    if (files === null || files === undefined) {
      this.set("financialDocuments", []);
    }
    return this.get("financialDocuments");
  }
  public set financialDocuments(value: AttachmentModel[]) { this.set("financialDocuments", value); }

  public get workInstructionDocuments(): AttachmentModel[] {
    const files = this.get("workInstructionDocuments");
    if (files === null || files === undefined) {
      this.set("workInstructionDocuments", []);
    }
    return this.get("workInstructionDocuments");
  }
  public set workInstructionDocuments(value: AttachmentModel[]) { this.set("workInstructionDocuments", value); }

  public get hsePlanDocuments(): AttachmentModel[] {
    const files = this.get("hsePlanDocuments");
    if (files === null || files === undefined) {
      this.set("hsePlanDocuments", []);
    }
    return this.get("hsePlanDocuments");
  }
  public set hsePlanDocuments(value: AttachmentModel[]) { this.set("hsePlanDocuments", value); }

  public get designDocuments(): AttachmentModel[] {
    const files = this.get("designDocuments");
    if (files === null || files === undefined) {
      this.set("designDocuments", []);
    }
    return this.get("designDocuments");
  }
  public set designDocuments(value: AttachmentModel[]) { this.set("designDocuments", value); }

  public get statsDocuments(): AttachmentModel[] {
    const files = this.get("statsDocuments");
    if (files === null || files === undefined) {
      this.set("statsDocuments", []);
    }
    return this.get("statsDocuments");
  }
  public set statsDocuments(value: AttachmentModel[]) { this.set("statsDocuments", value); }

  public get streetWorksDocuments(): AttachmentModel[] {
    const files = this.get("streetWorksDocuments");
    if (files === null || files === undefined) {
      this.set("streetWorksDocuments", []);
    }
    return this.get("streetWorksDocuments");
  }
  public set streetWorksDocuments(value: AttachmentModel[]) { this.set("streetWorksDocuments", value); }
}

Parse.Object.registerSubclass("Contract", ContractModel);

import * as Parse from "parse";

import { EmployeeModel } from "./employee.model";

export class EmployeeHolidayModel extends Parse.Object {
  className = "EmployeeHoliday";

  public get startDate(): Date { return this.get("startDate"); }
  public set startDate(value: Date) { this.set("startDate", value); }

  public get startPmOnly(): boolean { return this.get("startPmOnly"); }
  public set startPmOnly(value: boolean) { this.set("startPmOnly", value); }

  public get endDate(): Date { return this.get("endDate"); }
  public set endDate(value: Date) { this.set("endDate", value); }

  public get endAmOnly(): boolean { return this.get("endAmOnly"); }
  public set endAmOnly(value: boolean) { this.set("endAmOnly", value); }

  public get employee(): EmployeeModel { return this.get("employee"); }
  public set employee(value: EmployeeModel) { this.set("employee", value); }

  public get entitlementRequired(): number { return this.get("entitlementRequired"); }
  public set entitlementRequired(value: number) { this.set("entitlementRequired", value); }

}

Parse.Object.registerSubclass("EmployeeHoliday", EmployeeHolidayModel);

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  margin: 1rem;
}

.expiry-header {
  background: #E2D900;
}

.delete-container {
  position: relative;
}
.delete-container .btn-delete {
  position: absolute;
  right: 8px;
  top: 8px;
}

.card .card-footer {
  background-color: #f7f7f7;
}
.card .card-header {
  background-color: #f7f7f7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/attachment-card/attachment-card.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;AAEJ;;AAGE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".image-preview {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  height: 200px;\n  margin: 1rem;\n}\n\n.expiry-header {\n  background: #E2D900;\n}\n\n.delete-container {\n  position: relative;\n  .btn-delete {\n    position: absolute;\n    right: 8px;\n    top: 8px;\n  }  \n}\n\n.card {\n  .card-footer {\n    background-color: #f7f7f7;\n  }\n  .card-header{\n    background-color: #f7f7f7;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

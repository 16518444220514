import * as Parse from "parse";

import { JobVisitModel } from "./job-visit.model";
import { VehicleModel } from "./vehicle.model";

export class VehicleJobVisitModel extends Parse.Object {
  className = "VehicleJobVisit";

  public get vehicle(): VehicleModel { return this.get("vehicle"); }
  public set vehicle(value: VehicleModel) { this.set("vehicle", value); }

  public get jobVisit(): JobVisitModel { return this.get("jobVisit"); }
  public set jobVisit(value: JobVisitModel) { this.set("jobVisit", value); }

  public get start(): Date { return this.get("start"); }
  public set start(value: Date) { this.set("start", value); }

  public get end(): Date { return this.get("end"); }
  public set end(value: Date) { this.set("end", value); }
}

Parse.Object.registerSubclass("VehicleJobVisit", VehicleJobVisitModel);

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@authentication/authentication.service';

@Injectable()
export class AuthenticationGuardService implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authenticationService.mustChangePassword()) {
      if (state.url.indexOf('change-password') > -1) {
        return true;
      }
      this.router.navigate(['change-password']);
      return false;
    }
    
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['sign-in']);
    return false;
  }
}

import * as Parse from "parse";

export class CompanyModel extends Parse.Object {
  className = "Company";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

}

Parse.Object.registerSubclass("Company", CompanyModel);

import * as Parse from "parse";

export class AttachmentModel extends Parse.Object {
  className = "Attachment";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get description(): string { return this.get("description"); }
  public set description(value: string) { this.set("description", value); }

  public get expiryDate(): Date { return this.get("expiryDate"); }
  public set expiryDate(value: Date) { this.set("expiryDate", value); }

  public get file(): Parse.File { return this.get("file"); }
  public set file(value: Parse.File) { this.set("file", value); }

  public get preview(): Parse.File { return this.get("preview"); }
  public set preview(value: Parse.File) { this.set("preview", value); }

  public get takenAt(): Date { return this.get("takenAt"); }
  public set takenAt(value: Date) { this.set("takenAt", value); }

  public get comments(): string { return this.get("comments"); }
  public set comments(value: string) { this.set("comments", value); }

  public get expiry(): Date { return this.get("expiry"); }
  public set expiry(value: Date) { this.set("expiry", value); }
}

Parse.Object.registerSubclass("Attachment", AttachmentModel);

import { Component, OnInit, Input } from '@angular/core';
import { AttachmentModel, JobVisitModel } from '../../../model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-visit-card',
  templateUrl: './job-visit-card.component.html',
  styleUrls: ['./job-visit-card.component.scss']
})
export class JobVisitCardComponent implements OnInit {

  constructor(private router: Router) {}

  @Input() jobVisit: JobVisitModel;

  public attachment: AttachmentModel;

  ngOnInit() {
    const attachmentQuery = new Parse.Query(AttachmentModel);
    attachmentQuery.equalTo('jobVisit', this.jobVisit);
    attachmentQuery.include(['file', 'preview']);
    attachmentQuery.ascending('createdAt')
    attachmentQuery.first().then(result => {
      this.attachment = result;
    }, error => {
      console.error(JSON.stringify(error));
    });
  }

  selectedJobVisit() {
    this.router.navigate(['job-visit', this.jobVisit.id,]);
  }

}

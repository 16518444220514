import * as Parse from "parse";

export class InvoiceModel extends Parse.Object {
  className = "Invoice";

  public get by(): Parse.User { return this.get("by"); }
  public set by(value: Parse.User) { this.set("by", value); }

  public get date(): Date { return this.get("date"); }
  public set date(value: Date) { this.set("date", value); }

  public get amount(): number { return this.get("amount"); }
  public set amount(value: number) { this.set("amount", value); }

  public get number(): string { return this.get("number"); }
  public set number(value: string) { this.set("number", value); }

  public get retention(): boolean { return this.get("retention"); }
  public set retention(value: boolean) { this.set("retention", value); }

  public get retentionAmount(): number { return this.get("retentionAmount"); }
  public set retentionAmount(value: number) { this.set("retentionAmount", value); }
}

Parse.Object.registerSubclass("Invoice", InvoiceModel);

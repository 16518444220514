import * as Parse from "parse";

import { CompanyModel, AttachmentModel, EmployeeModel, JobModel } from ".";

export class TenderModel extends Parse.Object {
  className = "Tender";

  public get name(): string { return this.get("name"); }
  public get index(): number { return this.get("index"); }

  public get region(): string { return this.get("region"); }
  public set region(value: string) { this.set("region", value); }

  public get workstream(): string { return this.get("workstream"); }
  public set workstream(value: string) { this.set("workstream", value); }

  public get receivedDate(): Date { return this.get("receivedDate"); }
  public set receivedDate(value: Date) { this.set("receivedDate", value); }

  public get dueDate(): Date { return this.get("dueDate"); }
  public set dueDate(value: Date) { this.set("dueDate", value); }

  public get submitDate(): Date { return this.get("submitDate"); }
  public set submitDate(value: Date) { this.set("submitDate", value); }

  public get commercialLead(): Parse.User { return this.get("commercialLead"); }
  public set commercialLead(value: Parse.User) { this.set("commercialLead", value); }

  public get operationalLead(): Parse.User { return this.get("operationalLead"); }
  public set operationalLead(value: Parse.User) { this.set("operationalLead", value); }

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }

  public get project(): string { return this.get("project"); }
  public set project(value: string) { this.set("project", value); }

  public get accepted(): string { return this.get("accepted"); }
  public set accepted(value: string) { this.set("accepted", value); }

  public get value(): number { return this.get("value"); }
  public set value(value: number) { this.set("value", value); }

  public get description(): string { return this.get("description"); }
  public set description(value: string) { this.set("description", value); }

  public get submissionAttachments(): AttachmentModel[] {
    const files = this.get("submissionAttachments");
    if (files === null || files === undefined) {
      this.set("submissionAttachments", []);
    }
    return this.get("submissionAttachments");
  }
  public set submissionAttachments(value: AttachmentModel[]) { this.set("submissionAttachments", value); }

  public get documentsAttachments(): AttachmentModel[] {
    const files = this.get("documentsAttachments");
    if (files === null || files === undefined) {
      this.set("documentsAttachments", []);
    }
    return this.get("documentsAttachments");
  }
  public set documentsAttachments(value: AttachmentModel[]) { this.set("documentsAttachments", value); }

  public get workQuotedBy(): Parse.User { return this.get("workQuotedBy"); }
  public set workQuotedBy(value: Parse.User) { this.set("workQuotedBy", value); }

  public get workRecommendedBy(): EmployeeModel[] {
    const files = this.get("workRecommendedBy");
    if (files === null || files === undefined) {
      this.set("workRecommendedBy", []);
    }
    return this.get("workRecommendedBy");
  }
  public set workRecommendedBy(value: EmployeeModel[]) { this.set("workRecommendedBy", value); }

  public get initialJobVisit(): JobModel { return this.get("initialJobVisit"); }
  public set initialJobVisit(value: JobModel) { this.set("initialJobVisit", value); }
}

Parse.Object.registerSubclass("Tender", TenderModel);

import { Component } from '@angular/core';

@Component({
  template: `<div class="not-found">Page not found.</div>`,
  styles: [`
  .not-found {
    font-weight: bold;
    font-size: 2rem;
    color: white;
    text-align:center;
    margin: 8rem 0;
    color: grey;
  }`]
})
export class PageNotFoundComponent {}

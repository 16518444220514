import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portal-animation',
  templateUrl: './portal-animation.component.html',
  styleUrls: ['./portal-animation.component.scss']
})
export class PortalAnimationComponent {

  @Input() showText = true;

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar {
  height: 350px;
  padding: 2rem;
  transition: 1s ease-in-out;
  background-color: #F98100;
}
.top-bar .logo {
  width: 200px;
}

.portal-container {
  position: absolute;
  transition: 1s ease-in-out;
  right: 50%;
  top: 50px;
  margin-right: -150px;
  margin-top: 0;
}

.portal-container-minimised {
  transform: scale(0.2);
  right: 0;
  top: 0;
  margin-right: -75px;
  margin-top: -100px;
}

.top-bar-minimised {
  height: 92px;
}`, "",{"version":3,"sources":["webpack://./src/app/top-bar/top-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,0BAAA;EAIA,yBAAA;AAFF;AADE;EACE,YAAA;AAGJ;;AAEA;EACE,kBAAA;EACA,0BAAA;EACA,UAAA;EACA,SAAA;EACA,oBAAA;EACA,aAAA;AACF;;AAEA;EACE,qBAAA;EACA,QAAA;EACA,MAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".top-bar {\n  height: 350px;\n  padding: 2rem;\n  transition: 1s ease-in-out; \n  .logo {\n    width: 200px;\n  }\n  background-color: #F98100;\n}\n\n.portal-container {\n  position: absolute;\n  transition: 1s ease-in-out;\n  right: 50%;\n  top: 50px;\n  margin-right: -150px;\n  margin-top: 0;\n}\n\n.portal-container-minimised {\n  transform: scale(0.2);\n  right: 0;\n  top: 0;\n  margin-right: -75px;\n  margin-top: -100px;\n}\n\n.top-bar-minimised {\n  height: 92px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

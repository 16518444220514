import * as Parse from "parse";

import { EmployeeModel } from "./employee.model";
import { VehicleModel } from "./vehicle.model";

export class WorkSlotModel extends Parse.Object {
  className = "WorkSlot";

  public get job(): Date { return this.get("job"); }
  public set job(value: Date) { this.set("job", value); }

  public get startDate(): Date { return this.get("startDate"); }
  public set startDate(value: Date) { this.set("startDate", value); }

  public get startTime(): Date { return this.get("startTime"); }
  public set startTime(value: Date) { this.set("startTime", value); }

  public get endDate(): Date { return this.get("endDate"); }
  public set endDate(value: Date) { this.set("endDate", value); }

  public get endTime(): Date { return this.get("endTime"); }
  public set endTime(value: Date) { this.set("endTime", value); }

  public get employees(): EmployeeModel[] {
    const employees = this.get("employees");
    if (employees === null || employees === undefined) {
      this.set("employees", []);
    }
    return this.get("employees");
  }
  public set employees(value: EmployeeModel[]) { this.set("employees", value); }

  public get vehicles(): VehicleModel[] {
    const vehicles = this.get("vehicles");
    if (vehicles === null || vehicles === undefined) {
      this.set("vehicles", []);
    }
    return this.get("vehicles");
  }
  public set vehicles(value: VehicleModel[]) { this.set("vehicles", value); }

  public get price(): number { return this.get("price"); }
  public set price(value: number) { this.set("price", value); }
}

Parse.Object.registerSubclass("WorkSlot", WorkSlotModel);

import * as Parse from "parse";

export class UserActivityModel extends Parse.Object {
  className = "UserActivity";

  public get origin(): string { return this.get("origin"); }
  public set origin(value: string) { this.set("origin", value); }

  public get ip(): string { return this.get("ip"); }
  public set ip(value: string) { this.set("ip", value); }

  public get host(): string { return this.get("host"); }
  public set host(value: string) { this.set("host", value); }

  public get user(): Parse.User { return this.get("user"); }
  public set user(value: Parse.User) { this.set("user", value); }

  public get referrer(): string { return this.get("referrer"); }
  public set referrer(value: string) { this.set("referrer", value); }
}

Parse.Object.registerSubclass("UserActivity", UserActivityModel);

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentModel } from '../../../model';

@Component({
  selector: 'app-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: ['./attachment-card.component.scss']
})
export class AttachmentCardComponent {

  @Input() attachment: AttachmentModel;
  @Input() editable = false;

  @Output() deleteAttachment = new EventEmitter();

  public deleteAttachmentClicked() {
    this.deleteAttachment.emit(this.attachment);
  }

}

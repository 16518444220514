import * as Parse from "parse";

export class RigModel extends Parse.Object {
  className = "Rig";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }
}

Parse.Object.registerSubclass("Rig", RigModel);

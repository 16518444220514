import * as Parse from "parse";

export class JobVisitLocationModel extends Parse.Object {
  className = "JobVisitLocation";

  public get geoPoint(): Parse.GeoPoint { return this.get("geoPoint"); }
  public set geoPoint(value: Parse.GeoPoint) { this.set("geoPoint", value); }

  public get timestamp(): Date { return this.get("timestamp"); }
  public set timestamp(value: Date) { this.set("timestamp", value); }
}

Parse.Object.registerSubclass("JobVisitLocation", JobVisitLocationModel);

import * as Parse from "parse";
import { CompanyModel } from "./company.model";

export class TracksheetRateModel extends Parse.Object {
  className = "TracksheetRate";

  public get code(): string { return this.get("code"); }
  public set code(value: string) { this.set("code", value); }

  public get surfaceType(): string { return this.get("surfaceType"); }
  public set surfaceType(value: string) { this.set("surfaceType", value); }

  public get heading(): string { return this.get("heading"); }
  public set heading(value: string) { this.set("heading", value); }

  public get workActivityDescription(): string { return this.get("workActivityDescription"); }
  public set workActivityDescription(value: string) { this.set("workActivityDescription", value); }

  public get costPerUnit(): number { return this.get("costPerUnit"); }
  public set costPerUnit(value: number) { this.set("costPerUnit", value); }

  public get unit(): string { return this.get("unit"); }
  public set unit(value: string) { this.set("unit", value); }

  public get region(): string { return this.get("region"); }
  public set region(value: string) { this.set("region", value); }

  public get client(): CompanyModel { return this.get("client"); }
  public set client(value: CompanyModel) { this.set("client", value); }
}

Parse.Object.registerSubclass("TracksheetRate", TracksheetRateModel);

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .not-found {
    font-weight: bold;
    font-size: 2rem;
    color: white;
    text-align:center;
    margin: 8rem 0;
    color: grey;
  }`, "",{"version":3,"sources":["webpack://./src/app/page-not-found.component.ts"],"names":[],"mappings":";EACE;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,WAAW;EACb","sourcesContent":["\n  .not-found {\n    font-weight: bold;\n    font-size: 2rem;\n    color: white;\n    text-align:center;\n    margin: 8rem 0;\n    color: grey;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import * as Parse from "parse";

export class AdditionalCostItemModel extends Parse.Object {
  className = "AdditionalCostItem";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get cost(): number { return this.get("cost"); }
  public set cost(value: number) { this.set("cost", value); }
}

Parse.Object.registerSubclass("AdditionalCostItem", AdditionalCostItemModel);

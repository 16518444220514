import * as Parse from "parse";

import { JobModel } from "./job.model";
import { AttachmentModel } from "./attachment.model";
import { JobVisitLocationModel } from "./job-visit-location.model";

export class JobVisitModel extends Parse.Object {
  className = "JobVisit";

  public get job(): JobModel { return this.get("job"); }
  public set job(value: JobModel) { this.set("job", value); }

  public get reportedBy(): Parse.User { return this.get("reportedBy"); }
  public set reportedBy(value: Parse.User) { this.set("reportedBy", value); }

  public get version(): String { return this.get("version"); }
  public set version(value: String) { this.set("version", value); }

  public get start(): Date { return this.get("start"); }
  public set start(value: Date) { this.set("start", value); }

  public get trueStart(): Date { return this.get("trueStart"); }
  public set trueStart(value: Date) { this.set("trueStart", value); }

  public get end(): Date { return this.get("end"); }
  public set end(value: Date) { this.set("end", value); }

  public get trueEnd(): Date { return this.get("trueEnd"); }
  public set trueEnd(value: Date) { this.set("trueEnd", value); }

  public get startLocation(): JobVisitLocationModel { return this.get("startLocation"); }
  public set startLocation(value: JobVisitLocationModel) { this.set("startLocation", value); }

  public get endLocation(): JobVisitLocationModel { return this.get("endLocation"); }
  public set endLocation(value: JobVisitLocationModel) { this.set("endLocation", value); }

  public get startPhotos(): AttachmentModel[] {
    const files = this.get("startPhotos");
    if (files === undefined) {
      this.set("startPhotos", []);
    }
    return this.get("startPhotos");
  }
  public set startPhotos(value: AttachmentModel[]) { this.set("startPhotos", value); }

  public get startComments(): String { return this.get("startComments"); }
  public set startComments(value: String) { this.set("startComments", value); }

  public get endPhotos(): AttachmentModel[] {
    const files = this.get("endPhotos");
    if (files === undefined) {
      this.set("endPhotos", []);
    }
    return this.get("endPhotos");
  }
  public set endPhotos(value: AttachmentModel[]) { this.set("endPhotos", value); }

  public get workCompleted(): String { return this.get("workCompleted"); }
  public set workCompleted(value: String) { this.set("workCompleted", value); }

  public get reportingDeviceInstallation(): Parse.Installation { return this.get("reportingDeviceInstallation"); }
  public set reportingDeviceInstallation(value: Parse.Installation) { this.set("reportingDeviceInstallation", value); }

  public get pbuSignatureName(): string { return this.get("pbuSignatureName"); }
  public get pbuSignature(): Parse.File { return this.get("pbuSignature"); }

  public get clientSignatureName(): string { return this.get("clientSignatureName"); }
  public get clientSignature(): Parse.File { return this.get("clientSignature"); }

  public get employeeNames(): string[] { return this.get("employeeNames"); }


  // Health and Safety Questions

  public get areaFreeOfDamage(): number {  return this.get("areaFreeOfDamage"); }
  public get areaFreeOfDamageComments(): string {  return this.get("areaFreeOfDamageComments"); }

  public get taskInstructionReceived(): number {  return this.get("taskInstructionReceived"); }
  public get taskInstructionReceivedComments(): string {  return this.get("taskInstructionReceivedComments"); }

  public get utilityDrawingsAvailable(): number {  return this.get("utilityDrawingsAvailable"); }
  public get utilityDrawingsAvailableComments(): string {  return this.get("utilityDrawingsAvailableComments"); }

  public get catAndGenny(): number {  return this.get("catAndGenny"); }
  public get catAndGennyComments(): string {  return this.get("catAndGennyComments"); }

  public get permitInPlace(): number {  return this.get("permitInPlace"); }
  public get permitInPlaceComments(): string {  return this.get("permitInPlaceComments"); }

  public get informationTrainingAndEquipmentProvided(): number {  return this.get("informationTrainingAndEquipmentProvided"); }
  public get informationTrainingAndEquipmentProvidedComments(): string {
    return this.get("informationTrainingAndEquipmentProvidedComments");
  }

  public get siteLeftSafe(): number {  return this.get("siteLeftSafe"); }
  public get siteLeftSafeComments(): string {  return this.get("setSiteLeftSafeComments"); }


  // AquaJet Only

  public get startTravelMinutes(): number {  return this.get("startTravelMinutes"); }
  public get endTravelMinutes(): number {  return this.get("endTravelMinutes"); }
  public get recommendations(): string {  return this.get("recommendations"); }
  public get paymentType(): string {  return this.get("paymentType"); }

  public get tankerAttended(): number {  return this.get("tankerAttended"); }
  public get DigUpAttended(): number {  return this.get("digUpAttended"); }
  public get cctvAttended(): number {  return this.get("cctvAttended"); }
  public get calloutAttended(): number {  return this.get("calloutAttended"); }
  public get LinerAttended(): number {  return this.get("linerAttended"); }
  public get HiabAttended(): number {  return this.get("hiabAttended"); }

  // Alconex Only
  public get layGas(): number {  return this.get("layGas"); }
  public get layElectric(): number {  return this.get("layElectric"); }
  public get layWater(): number {  return this.get("layWater"); }
  public get excavationOn(): number {  return this.get("excavationOn"); }
  public get excavationOff(): number {  return this.get("excavationOff"); }
  public get gasServices(): number {  return this.get("gasServices"); }
  public get waterServices(): number {  return this.get("waterServices"); }
  public get electricServices(): number {  return this.get("electricServices"); }
  public get csep(): number {  return this.get("csep"); }
}
Parse.Object.registerSubclass("JobVisit", JobVisitModel);

import * as Parse from "parse";

export class FilterBookmarkModel extends Parse.Object {
  className = "FilterBookmark";

  public get name(): string { return this.get("name"); }
  public set name(value: string) { this.set("name", value); }

  public get createdBy(): Parse.User { return this.get("createdBy"); }
  public set createdBy(value: Parse.User) { this.set("createdBy", value); }

  public get path(): string { return this.get("path"); }
  public set path(value: string) { this.set("path", value); }

  public get filter(): string { return this.get("filter"); }
  public set filter(value: string) { this.set("filter", value); }

  public get sortKey(): string { return this.get("sortKey"); }
  public set sortKey(value: string) { this.set("sortKey", value); }

  public get sortDescending(): boolean { return this.get("sortDescending"); }
  public set sortDescending(value: boolean) { this.set("sortDescending", value); }
}

Parse.Object.registerSubclass("FilterBookmark", FilterBookmarkModel);

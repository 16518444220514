import * as Parse from "parse";

export class VehicleModel extends Parse.Object {
  className = "Vehicle";

  public get registration(): string { return this.get("registration"); }
  public set registration(value: string) { this.set("registration", value); }

  public get type(): string { return this.get("type"); }
  public set type(value: string) { this.set("type", value); }

  public get office(): string { return this.get("office"); }
  public set office(value: string) { this.set("office", value); }

  public get archived(): boolean { return this.get("archived"); }
  public set archived(value: boolean) { this.set("archived", value); }

  public get subOrganisation(): string { return this.get("subOrganisation"); }
  public set subOrganisation(value: string) { this.set("subOrganisation", value); }
}

Parse.Object.registerSubclass("Vehicle", VehicleModel);

import * as Parse from "parse";
import { TracksheetRateModel } from "./tracksheet-rate.model";

export class TracksheetItemModel extends Parse.Object {
  className = "TracksheetItem";

  public get rate(): TracksheetRateModel { return this.get("rate"); }
  public set rate(value: TracksheetRateModel) { this.set("rate", value); }

  public get quantity(): number { return this.get("quantity"); }
  public set quantity(value: number) { this.set("quantity", value); }

  public get uplift(): number { return this.get("uplift"); }
  public set uplift(value: number) { this.set("uplift", value); }

}